import { Alert as MantineAlert } from '@mantine/core';
import type { AlertProps as MantineAlertProps } from '@mantine/core';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import { FiAlertTriangle, FiCheckCircle, FiInfo } from 'react-icons/fi';

import classes from './alert.module.css';

const alertVariants = cva(classes.alert, {
  variants: {
    variant: {
      light: '',
      filled: '',
      outline: '',
    },
    color: {
      info: classes.info,
      warning: classes.warning,
      error: classes.error,
      success: classes.success,
      gray: classes.gray,
      primary: classes.primary,
    },
  },
  defaultVariants: {
    variant: 'light',
    color: 'gray',
  },
});

export type AlertProps = Omit<MantineAlertProps, 'color' | 'sx'> & {
  color?: VariantProps<typeof alertVariants>['color'] | MantineAlertProps['color'];
};

export const Alert = (props: AlertProps) => {
  const { color } = props;

  const getColor = () => {
    switch (color) {
      case 'error':
        return 'red';

      case 'warning':
        return 'yellow';

      case 'info':
        return 'blue';

      case 'success':
        return 'green';

      default:
        return color ?? 'gray';
    }
  };

  const getIcon = () => {
    switch (color) {
      case 'info':
        return <FiInfo />;

      case 'warning':
        return <FiAlertTriangle />;

      case 'error':
        return <FiAlertTriangle />;

      case 'success':
        return <FiCheckCircle />;

      default:
        return props.icon;
    }
  };

  return <MantineAlert {...props} color={getColor()} icon={getIcon()} />;
};
