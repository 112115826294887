import { useSession } from 'next-auth/react';

import { useGetMyHighlighterAccountsQuery } from '@highlight-cp/highlighter-api';

import { ProfileSurveyStatus } from './profile-view/types';

export const useGetUser = () => {
  const { data: session, status: sessionStatus } = useSession();

  const { data: account, isLoading } = useGetMyHighlighterAccountsQuery(undefined, {
    skip: !session?.accessToken,
  });

  // stay in loading state while api is fetching
  const status = isLoading ? 'loading' : sessionStatus;
  const isAuthenticated = status === 'authenticated';

  const isAuthorized = isAuthenticated && !!account;
  const hasNoAccount = isAuthenticated && !account;
  const isNewAccount = account?.status === 'new' && (!account?.phoneVerified || !account?.address);
  // user has at least completed the first step of the account process (phone verified), but has not completed the entire flow
  const isAccountDetailsInProgress = isNewAccount && account?.phoneVerified;
  const accountDetailsStatus: ProfileSurveyStatus = isAccountDetailsInProgress
    ? ProfileSurveyStatus.IN_PROGRESS
    : isNewAccount
      ? ProfileSurveyStatus.NOT_STARTED
      : ProfileSurveyStatus.COMPLETE;

  // everything but active and new
  const isAccountLocked =
    isAuthorized && ['banned', 'disabled', 'deleted'].includes(account?.status ?? '');
  const isUserQuit = isAuthorized && (account?.status as string) == 'quit';

  const fullName = `${account?.firstName ?? ''} ${account?.lastName ?? ''}`;

  return {
    account,
    fullName,
    hasNoAccount,
    accountDetailsStatus,
    isAccountLocked,
    isUserQuit,
    isAuthorized,
    isLoading,
    isNewAccount,
    session,
    status,
  };
};
