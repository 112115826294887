import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import type { UnstyledButtonProps as MantineUnstyledButtonProps } from '@mantine/core';
import { UnstyledButton as MantineUnstyledButton } from '@mantine/core';

type UnstyledButtonProps = Omit<MantineUnstyledButtonProps, 'sx'>;

export const UnstyledButton = forwardRef<
  ElementRef<typeof MantineUnstyledButton>,
  ComponentPropsWithoutRef<typeof MantineUnstyledButton> &
    Omit<UnstyledButtonProps, keyof ComponentPropsWithoutRef<typeof MantineUnstyledButton>>
>((props, ref) => {
  return <MantineUnstyledButton {...props} ref={ref} />;
});

UnstyledButton.displayName = 'UnstyledButton';
