export const TEXT_VARIANT = {
  HEADLINE: 'headline',
  SUBHEAD: 'subhead',
  BUTTON: 'button',

  BODY_LG: 'bodyLg',
  BODY_MD: 'bodyMd',
  BODY_SM: 'bodySm',

  BODY_LG_STRONG: 'bodyLgStrong',
  BODY_MD_STRONG: 'bodyMdStrong',
  BODY_SM_STRONG: 'bodySmStrong',

  FIELD_LABEL: 'fieldLabel',
  CAPTION: 'caption',
  TABLE_HEADER: 'tableHeader',
  SMALL_LABEL: 'smallLabel',

  TITLE_XL: 'titleXl',
  TITLE_LG: 'titleLg',
  TITLE_MD: 'titleMd',
  TITLE_SM: 'titleSm',
  TITLE_XS: 'titleXs',
} as const;

export const DEFAULT_TEXT_VARIANT = TEXT_VARIANT.BODY_MD;

export const TEXT_FONT = {
  INTER: 'inter',
  POPPINS: 'poppins',
} as const;

export const DEFAULT_TEXT_FONT = TEXT_FONT.INTER;
