import { FiInfo } from 'react-icons/fi';

import { Text } from '../text';
import type { AlertProps } from './alert';
import { Alert } from './alert';
import classes from './info.module.css';

const INFO_DEFAULT_COLOR = 'blue.7';
const INFO_DEFAULT_ICON = <FiInfo size={24} />;
const INFO_DEFAULT_RADIUS = 'md';

export const Info = (props: AlertProps) => {
  const {
    children,
    color = INFO_DEFAULT_COLOR,
    icon = INFO_DEFAULT_ICON,
    radius = INFO_DEFAULT_RADIUS,
    p = 'xs',
    ...rest
  } = props;

  return (
    <Alert
      classNames={{
        icon: classes.icon,
        root: classes.root,
        wrapper: classes.wrapper,
        ...rest.classNames,
      }}
      color={color}
      icon={icon}
      radius={radius}
      p={p}
      {...rest}
    >
      <Text color={color ?? INFO_DEFAULT_COLOR} variant="bodySmStrong">
        {children}
      </Text>
    </Alert>
  );
};
