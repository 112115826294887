import { forwardRef } from 'react';
import { createPolymorphicComponent, Button as MantineButton } from '@mantine/core';
import type { DefaultMantineColor, ButtonProps as MantineButtonProps } from '@mantine/core';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

import classes from './button.module.css';

// https://v6.mantine.dev/guides/polymorphic/

const buttonVariants = cva(classes.button, {
  variants: {
    variant: {
      solid: classes.solid,
      outline: classes.outline,
      neutral: classes.neutral,
      link: classes.link,
      ghost: classes.ghost,
      destructive: classes.destructive,
    },
    color: {
      primary: '',
      danger: '',
      warning: '',
      success: '',
      info: '',
    },
    size: {
      xs: classes.xsmall,
      sm: classes.small,
      md: classes.medium,
      lg: classes.large,
      xl: classes.xlarge,
    },
  },
  defaultVariants: {
    variant: 'solid',
    size: 'md',
    color: 'primary',
  },
});

type ButtonVariants = VariantProps<typeof buttonVariants>;

type ButtonProps = Omit<MantineButtonProps, 'variant' | 'sx'> & {
  variant?: VariantProps<typeof buttonVariants>['variant'] | MantineButtonProps['variant'];
  size?: VariantProps<typeof buttonVariants>['size'];
  color?: VariantProps<typeof buttonVariants>['color'] | MantineButtonProps['color'];
};

const _Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant, size, color, className, ...rest } = props;

  const getMantineVariant = () => {
    switch (variant) {
      case 'solid':
      case 'destructive':
        return 'filled';

      case 'neutral':
      case 'ghost':
        return 'default';

      case 'link':
        return 'subtle';

      default:
        return variant as string;
    }
  };

  const getMantineColor = (): DefaultMantineColor | undefined => {
    if (!color) return undefined;

    switch (color) {
      case 'danger':
      case 'destructive':
        return 'red';
      case 'warning':
        return 'yellow';
      case 'info':
        return 'blue';
      case 'success':
        return 'green';
      default:
        return color as DefaultMantineColor;
    }
  };

  return (
    <MantineButton
      ref={ref}
      radius="xl"
      size={size}
      {...rest}
      color={getMantineColor()}
      variant={getMantineVariant()}
      classNames={{
        root: buttonVariants({ variant: variant as ButtonVariants['variant'], size, className }),
        leftIcon: classes.leftIcon,
        ...props.classNames,
      }}
    />
  );
});

_Button.displayName = 'Button';

export const Button = createPolymorphicComponent<'button', ButtonProps>(_Button);
