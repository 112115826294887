import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useDispatch } from 'react-redux';

import { useGetUser } from 'features/account/use-get-user';
import { setCredentials, setLandingRoute, signOutUser } from 'store/app/app-slice';

const maxAge = parseInt(process.env.NEXT_AUTH_SESSION_AGE_SECONDS ?? '10');

const PUBLIC_URLS = [
  '/',
  '/referral-code',
  '/unsubscribe/[highlighter_guid]',
  '/reject-opt-in/[survey-id]/[highlighter-id]',
];

export const useAuth = () => {
  const { session, status } = useGetUser();
  const dispatch = useDispatch();

  const handleSession = useCallback(
    (currentSession: ISession) => {
      if (!currentSession) return;

      if (
        currentSession.error ??
        (currentSession.expiresAt && currentSession.expiresAt <= Date.now())
      ) {
        void signOut({
          callbackUrl: '/',
        });
        dispatch(signOutUser());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const newSession: ISession = session!;
    if (newSession?.user?.email && newSession?.accessToken && status === 'authenticated') {
      dispatch(
        setCredentials({
          email: newSession.user.email,
          token: newSession.accessToken,
        }),
      );
    }

    const sessionTimer = setInterval(() => handleSession(newSession), maxAge * 1000);
    // check session now before initial interval check
    handleSession(newSession);

    return () => clearInterval(sessionTimer);
  }, [dispatch, handleSession, session, status]);
};

export function AuthGuard({ children }: { children: JSX.Element }) {
  const { push, route } = useRouter();
  const dispatch = useDispatch();

  useAuth();
  const { account, status, isAccountLocked, isUserQuit } = useGetUser();

  useEffect(() => {
    // landing on private route when not auth'd
    if (status === 'unauthenticated' && !PUBLIC_URLS.includes(route)) {
      dispatch(setLandingRoute({ landingRoute: route }));
      void push('/');
    } else if (status === 'authenticated') {
      // if auth'd, we have an account, and on public route; go home
      if (account && !isAccountLocked && !isUserQuit && PUBLIC_URLS.includes(route)) {
        void push('/home');
      }

      // if auth'd, we dont have an account (or its locked) and on private route; go to landing page
      if ((!account || isAccountLocked || isUserQuit) && !PUBLIC_URLS.includes(route)) {
        void push('/');
      }
    }
  }, [status, route, push, dispatch, account, isAccountLocked, isUserQuit]);

  if (!account && !PUBLIC_URLS.includes(route)) {
    return;
  }

  return children;
}
