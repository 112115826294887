import type { CSSProperties } from 'react';
import { Text as MantineText } from '@mantine/core';
import type { TextProps as MantineTextProps } from '@mantine/core';
import type { PolymorphicComponentProps } from '@mantine/utils';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

import titleClasses from '../title/title.module.css';
import { DEFAULT_TEXT_FONT, DEFAULT_TEXT_VARIANT, TEXT_FONT, TEXT_VARIANT } from './constants';
import classes from './text.module.css';

const textVariants = cva(classes.text, {
  variants: {
    variant: {
      [TEXT_VARIANT.HEADLINE]: classes.headline,
      [TEXT_VARIANT.SUBHEAD]: classes.subhead,
      [TEXT_VARIANT.BUTTON]: classes.button,

      [TEXT_VARIANT.BODY_LG]: classes.bodyLg,
      [TEXT_VARIANT.BODY_MD]: classes.bodyMd,
      [TEXT_VARIANT.BODY_SM]: classes.bodySm,

      [TEXT_VARIANT.BODY_LG_STRONG]: classes.bodyLgStrong,
      [TEXT_VARIANT.BODY_MD_STRONG]: classes.bodyMdStrong,
      [TEXT_VARIANT.BODY_SM_STRONG]: classes.bodySmStrong,

      [TEXT_VARIANT.FIELD_LABEL]: classes.fieldLabel,
      [TEXT_VARIANT.CAPTION]: classes.caption,
      [TEXT_VARIANT.TABLE_HEADER]: classes.tableHeader,
      [TEXT_VARIANT.SMALL_LABEL]: classes.smallLabel,

      // should we allow text to be styled like a title?
      [TEXT_VARIANT.TITLE_XL]: titleClasses.xLarge,
      [TEXT_VARIANT.TITLE_LG]: titleClasses.large,
      [TEXT_VARIANT.TITLE_MD]: titleClasses.medium,
      [TEXT_VARIANT.TITLE_SM]: titleClasses.small,
      [TEXT_VARIANT.TITLE_XS]: titleClasses.xSmall,
    },
    font: {
      [TEXT_FONT.INTER]: classes.inter,
      [TEXT_FONT.POPPINS]: classes.poppins,
    },
  },
  defaultVariants: {
    variant: DEFAULT_TEXT_VARIANT,
    font: DEFAULT_TEXT_FONT,
  },
});

type _TextProps = Omit<MantineTextProps, 'variant' | 'sx'> & {
  variant?: VariantProps<typeof textVariants>['variant'];
  font?: VariantProps<typeof textVariants>['font'];
};

//? forced by mantine props for component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TextProps = PolymorphicComponentProps<any, _TextProps>;

export const Text = (props: TextProps) => {
  const { children, className, variant, font = DEFAULT_TEXT_FONT, ...rest } = props;

  // See https://www.figma.com/design/9e7kKlUqz8u3G2tDAyptiD/%E2%9C%85-Highlight-Design-System?node-id=2670-10881&t=MRzBUHf0oypGyDVb-0
  const getStyles = (): CSSProperties => {
    switch (variant) {
      case TEXT_VARIANT.HEADLINE:
        return {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
        };
      case TEXT_VARIANT.SUBHEAD:
        return {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '20px',
        };
      case TEXT_VARIANT.BUTTON:
        return {
          fontSize: '15px',
          fontWeight: 700,
          lineHeight: '20px',
        };

      case TEXT_VARIANT.BODY_LG:
        return {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
        };
      case TEXT_VARIANT.BODY_MD:
        return {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
        };
      case TEXT_VARIANT.BODY_SM:
        return {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
        };

      case TEXT_VARIANT.BODY_LG_STRONG:
        return {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',
        };
      case TEXT_VARIANT.BODY_MD_STRONG:
        return {
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '20px',
        };
      case TEXT_VARIANT.BODY_SM_STRONG:
        return {
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
        };

      case TEXT_VARIANT.FIELD_LABEL:
        return {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '16px',
          textTransform: 'capitalize',
        };
      case TEXT_VARIANT.CAPTION:
        return {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
        };
      case TEXT_VARIANT.TABLE_HEADER:
        return {
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '16px',
          textTransform: 'capitalize',
        };
      case TEXT_VARIANT.SMALL_LABEL:
        return {
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: '16px',
        };

      case TEXT_VARIANT.TITLE_XL:
        return {
          fontFamily: 'var(--mantine-font-family-headings, Poppins, sans-serif)',
          fontSize: '48px',
          fontWeight: 600,
          lineHeight: '56px',
          letterSpacing: '-0.96px',
        };

      case TEXT_VARIANT.TITLE_LG:
        return {
          fontFamily: 'var(--mantine-font-family-headings, Poppins, sans-serif)',
          fontSize: '36px',
          fontWeight: 600,
          lineHeight: '44px',
          letterSpacing: '-0.72px',
        };

      case TEXT_VARIANT.TITLE_MD:
        return {
          fontFamily: 'var(--mantine-font-family-headings, Poppins, sans-serif)',
          fontSize: '28px',
          fontWeight: 600,
          lineHeight: '32px',
          letterSpacing: '-0.56px',
        };

      case TEXT_VARIANT.TITLE_SM:
        return {
          fontFamily: 'var(--mantine-font-family-headings, Poppins, sans-serif)',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '28px',
          letterSpacing: '-0.48px',
        };

      case TEXT_VARIANT.TITLE_XS:
        return {
          fontFamily: 'var(--mantine-font-family-headings, Poppins, sans-serif)',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.4px',
        };

      default:
        return {};
    }
  };

  return (
    <MantineText
      {...rest}
      className={textVariants({ variant, font, className })}
      style={{ ...getStyles(), ...props.style }}
    >
      {children}
    </MantineText>
  );
};
